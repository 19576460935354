import React from "react";
import {useParams} from "react-router";
import {Grid} from "@material-ui/core";

const Artwork = ({portfolio}) => {
    let { work } = useParams();
    const artwork = portfolio.filter(art => art.slug === work)[0]
    return (
        <Grid container spacing={5}>
            {artwork.acf.gallery.map(image => (
                <Grid key={image.id} item xs={12} sm={6} md={4}>
                    <img alt={image.title} style={{width: '100%'}} src={image['full_image_url']} />
                </Grid>
            ))}
        </Grid>
    )
}

export default Artwork