import React from 'react';
import {Grid} from "@material-ui/core";
import Image from "./Image";
import Gallery from "./Gallery";

const Home = ({portfolio}) => {
    return (
        <Gallery portfolio={portfolio} />
    )
}

export default Home