import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {Grid, useMediaQuery, Hidden} from "@material-ui/core";
import {useSelector} from "react-redux";
import materialTheme from "../material-styles";
import Home from "./Home";
import NavBar from "./Nav";
import TopBar from "./TopBar";
import Artwork from "./Artwork";
import About from "./About";

function Main() {
    const portfolio = useSelector(state => state.wordpress.portfolio)
    const isMobile = useMediaQuery(materialTheme.breakpoints.down("xs"))
    return (
        <>
            <TopBar portfolio={portfolio} isMobile={isMobile} />
            <div style={{maxWidth: '975px', margin: '0 30px'}}>
                {portfolio && (
                    <Grid container>
                        <Hidden xsDown>
                            <Grid item sm={3}>
                                <NavBar portfolio={portfolio} />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={9}>
                            <Switch>
                                <Route exact path="/">
                                    <Home portfolio={portfolio} />
                                </Route>
                                <Route path="/artworks/:work">
                                    <Artwork portfolio={portfolio} />
                                </Route>
                                <Route path="/about">
                                    <About />
                                </Route>
                            </Switch>
                        </Grid>
                    </Grid>
                )}
            </div>
        </>
    )
}

export default Main