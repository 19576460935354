import React from 'react'
import useWordpressData from "../providers/WordpressDataProvider";
import {useSelector} from "react-redux";
import Img from "./Img";

const Image = ({title, imageId, medium}) => {
    useWordpressData(`media/${imageId}`, [])
    const image = useSelector(state => state.wordpress[`media-${imageId}`])
    return (
        <div style={{height: '100%', textAlign: 'right', paddingBottom: '20px'}}>
            {image && <Img medium={medium} data={image} />}
            <div style={{margin: '10px 0'}}>{title}</div>
        </div>
    )
}

export default Image