/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'angostura/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'angostura/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const baseUrl = 'http://www.elanvivienne.com/wp/';
export const woocommerceVersion = 'wc/v3';

export const FETCH_WORDPRESS_DATA = 'WordpressDataProvider/FETCH_DATA';
export const SET_WORDPRESS_DATA = 'WordpressDataProvider/SET_DATA';