import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import materialTheme from './material-styles'
import GlobalStyle from './global-styles'
import useWordpressData from "./providers/WordpressDataProvider";
import {useSelector} from "react-redux";
import Main from "./containers";

function App() {
    useWordpressData('portfolio', [])
    return (
        <ThemeProvider theme={createMuiTheme(materialTheme)}>
            <Main />
            <GlobalStyle />
        </ThemeProvider>
    )
}

export default App