import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * , *:before, *:after{ 
     box-sizing:border-box; 
     -moz-box-sizing:border-box; 
     -webkit-box-sizing:border-box; 
     -ms-box-sizing:border-box;
   }
  html * { -webkit-text-size-adjust: none; }
  html,
  body {
    padding-right: 0 !important; 
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: monospace, serif;
    font-size: 12px;
    line-height: 24px;
  }
  a {
    color: black;
    text-decoration: none;
  }
`;

export default GlobalStyle;
