import React from "react";
import {Link, useLocation} from 'react-router-dom';

const NavBar = ({portfolio}) => {
    const location = useLocation();
    return (
        <div style={{
            position: 'fixed',
            paddingRight: '30px',
        }}>
            {portfolio.map(item => (
                <span key={item.slug}>
                    <Link to={`/artworks/${item.slug}`} style={{textDecoration: location.pathname === `/artworks/${item.slug}` && 'underline'}}>{item.title.rendered}</Link>
                    <br/>
                </span>
            ))}
            <Link to="/about" style={{textDecoration: location.pathname === '/about' && 'underline'}}>ABOUT/CONTACT</Link>
            <br/>
            <a target="_blank" href="https://www.instagram.com/elanvivienne/">INSTAGRAM</a>
        </div>
    )
}

export default NavBar