import React from "react";
import useWordpressData from "../providers/WordpressDataProvider";
import {useSelector} from "react-redux";

const Image = ({props}) => <img {...props} />

const About = ({portfolio}) => {
    useWordpressData('pages/33', [])
    const about = useSelector(state => state.wordpress['pages-33'])
    console.log('pdpdp', about)
    return (
        <div dangerouslySetInnerHTML={{__html: about && about.content.rendered}} />
    )
}

export default About