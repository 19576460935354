import React from 'react'
import useWordpressData from "../providers/WordpressDataProvider";
import {useSelector} from "react-redux";

const Img = ({data, medium}) => {
    const link = medium ? data.media_details.sizes.medium.source_url : data.source_url
    return (
        <div style={{backgroundImage: `url(${link})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '100%'}}>
            <img style={{width: '100%', opacity: 0}} src={link} alt="Elan Vivienne artwork" />
        </div>
    )
}

export default Img