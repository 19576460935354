import React from 'react';
import {Grid} from "@material-ui/core";
import Image from "./Image";
import {Link} from "react-router-dom";

const Gallery = ({portfolio}) => {
    return (
        <div>
            <Grid container spacing={5}>
                {portfolio.map(item => (
                    <Grid key={item.title.rendered} item xs={12} sm={6} md={4}>
                        <Link to={`/artworks/${item.slug}`}><Image title={item.title.rendered} imageId={item.featured_media} /></Link>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default Gallery