import React, {useState} from "react";
import {SwipeableDrawer} from "@material-ui/core";
import NavBar from "./Nav";
import {Link} from 'react-router-dom';
import {makeStyles} from "@material-ui/core/styles";

const TopBar = ({portfolio, isMobile}) => {
    const [open, setOpen] = useState(false)
    const useStyles = makeStyles({
        drawerRoot: {
            height: window.innerHeight,
            width: '100%',
            zIndex: 1
        },
    });
    const classes = useStyles()
    return (
        <div style={{
            maxWidth: '1005px',
            padding: '50px 30px',
            fontSize: 'small',
            textDecoration: 'underline',
            position: 'relative'
        }}>
            <div style={{position: !isMobile && 'fixed'}}>
                <Link to="/">ELAN VIVIENNE</Link>
                {isMobile && (
                    <div
                        style={{cursor: 'pointer', position: "absolute", top: '50px', right: '30px'}}
                        onClick={() => setOpen(true)}
                    >
                        <span style={{textDecoration: 'underline', float: "right", textAlign: 'right'}}>MENU</span>
                    </div>
                )}
            </div>
            <SwipeableDrawer
                classes={{paper: classes.drawerRoot}}
                onClose={() => setOpen(false)}
                open={open} anchor="top"
                onOpen={() => setOpen(true)}
            >
                <div style={{
                    maxWidth: '1005px',
                    padding: '50px 30px',
                    fontSize: 'small',
                    textDecoration: 'underline',
                    position: 'relative'
                }}>
                    <div style={{cursor: 'pointer', position: 'absolute', right: '30px'}} onClick={() => setOpen(false)}>
                        <span style={{textDecoration: 'underline', float: "right", textAlign: 'right'}}>CLOSE</span>
                    </div>
                    <NavBar portfolio={portfolio} />
                </div>
            </SwipeableDrawer>
        </div>
    )
}

export default TopBar