import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

const materialTheme = {
    breakpoints,
    typography: {
        fontFamily: [
            'monospace',
        ].join(','),
    },
};

export default materialTheme;