import {call, fork, join, put, takeEvery} from 'redux-saga/effects';
import Wordpress from '@wordpress/api-fetch';

import { setWordpressData } from './actions';
import {
  baseUrl,
  FETCH_WORDPRESS_DATA,
} from '../../constants';

function* fetchData({ payload: { key, apiPath } }) {
  const data = yield Wordpress( { path: `${baseUrl}wp-json/wp/v2/${apiPath}?per_page=100` } )
    .then(response => response)
    .catch(() => null);
  if (data) {
    yield put(setWordpressData(key, data));
  }
}

export function* queueFetchData(action) {
  yield call(fetchData, action);
}

function takeLeadingAPI(typeOrPattern, saga, ...args) {
  const instanceMap = new Map();
  return fork(function* forker() {
    yield takeEvery(typeOrPattern, function* worker(action) {
      const {
        payload: { key },
      } = action;
      if (!instanceMap.get(key)) {
        const task = yield fork(saga, action, ...args);
        instanceMap.set(key, task);
        yield join(task);
        instanceMap.delete(key);
      }
    });
  });
}

export default function* authProviderSaga() {
  yield takeLeadingAPI(FETCH_WORDPRESS_DATA, queueFetchData);
}
